import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { HeroContent } from '@/components/common/Hero/HeroContent';
import { Section, WidthConstraint } from '@/components/layout/Section';
import { useSiteSettings } from '@/lib/useSiteSettings';

import type { HeroContentProps } from '@/components/common/Hero/HeroContent/HeroContent';
import type { SanityFullBackgroundImageHeroType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface FullBackgroundImageHeroProps
  extends Omit<SanityFullBackgroundImageHeroType, 'heroContent'>,
    BlockWithAudience {
  heroContent: HeroContentProps;
}

export const FullBackgroundImage = ({
  backgroundImage,
  mobileBackgroundImage,
  heroContent,
  contentAlignment,
  backgroundColour,
  pageAudience,
  isAudienceSwitcherEnabled,
}: FullBackgroundImageHeroProps) => {
  const backgroundColourToken = backgroundColour?.token || '--transparent';
  const siteSettings = useSiteSettings();
  const newNavigation = siteSettings?.newNavigation;

  return (
    <Section
      backgroundPosition={
        contentAlignment === 'left'
          ? `75% ${backgroundImage?.verticalAlignment ?? 'center'}`
          : 'center'
      }
      backgroundPositionMobile={
        contentAlignment === 'left'
          ? `75% ${mobileBackgroundImage?.verticalAlignment ?? 'center'}`
          : 'center'
      }
      backgroundImage={backgroundImage}
      backgroundImageMobile={mobileBackgroundImage}
      priority
      backgroundColour={backgroundColourToken}
      verticalPadding="hero"
      breakout
      className="full-background-image"
      newNavigation={newNavigation}
    >
      <StyledConstraint>
        <ContentWrapper contentAlignment={contentAlignment}>
          <HeroContent
            contentAlignment={contentAlignment}
            backgroundColour={
              heroContent.textColour.token === '--text-strong'
                ? '--transparent'
                : '--background-primary'
            }
            {...heroContent}
            pageAudience={pageAudience}
            isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          />
        </ContentWrapper>
      </StyledConstraint>
    </Section>
  );
};

const ContentWrapper = styled.div<
  Pick<SanityFullBackgroundImageHeroType, 'contentAlignment'>
>`
  width: 100%;

  ${screen.sm} {
    width: ${({ contentAlignment }) =>
      contentAlignment === 'center' ? '100%' : '66%'};
  }
`;

const StyledConstraint = styled(WidthConstraint)`
  min-height: 520px;
  max-height: 840px;
  height: 50vh;

  display: flex;
  align-items: center;

  ${screen.sm} {
    min-height: 600px;
  }

  ${screen.lg} {
    min-height: 640px;
    max-height: 1000px;
  }

  ${screen.xl} {
    min-height: 700px;
  }
`;
